import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

class NavigationBurger extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  bindFunctions () {
    this.triggerMenu = this.triggerMenu.bind(this)
    this.resizeSticky = this.resizeSticky.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-toggle-menu]', this.triggerMenu)
  }

  resolveElements () {
    this.$menu = $('.menu', this)
    this.$html = $('html')
    this.$window = $(window)
  }

  connectedCallback () {
    // this.$window.on('resize', this.resizeSticky)
    this.$window.on('scroll', this.resizeSticky)

  }

  triggerMenu (e) {
    this.$.toggleClass('flyntComponent-menuIsOpen')
    if (this.$.hasClass('flyntComponent-menuIsOpen') && isMobile()) {
      disableBodyScroll(this.$menu.get(0))
    } else {
      enableBodyScroll(this.$menu.get(0))
    }
  }

  resizeSticky(e) {
    const scrollTop = this.$window.scrollTop()
    // console.log(scrollTop);
    
    if (scrollTop > 59) {
      this.$html.addClass('scrolled')
    }
    if (scrollTop < 60) {
      this.$html.removeClass('scrolled')
    }
  }
}

function isMobile () {
  return window.matchMedia('(max-width: 767px)').matches
}

window.customElements.define('flynt-navigation-burger', NavigationBurger, {
  extends: 'nav'
})
