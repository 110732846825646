import $ from 'jquery'

class BlockCookieConsent extends window.HTMLDivElement {
  constructor (self) {
    self = super(self)
    self.$ = $(self)
    self.setOptions()
    self.resolveElements()
    return self
  }

  setOptions () {
   this.cookieName   = 'GTM-optout'
   this.cookieTrue  = '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'
   this.cookieFalse = '=true; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
  }

  resolveElements () {
    this.$saveButton = $('#saveCookie', this)
    this.$acceptAllButton = $('#acceptCookie', this)
    this.$acceptStatsCheckbox = $('.cookie-stats', this)
    this.$denyAllButton = $('#denyCookie', this)
    
  }

  connectedCallback () {
    this.setacceptStats()
    this.$saveButton.on('click', this.saveSettings.bind(this))
    this.$acceptAllButton.on('click', this.acceptCookieAll.bind(this))
    this.$denyAllButton.on('click', this.denyCookieAll.bind(this))
    this.$acceptStatsCheckbox.on('click', this.acceptStatsCheckbox.bind(this))
  }

  // set state on page load
  setacceptStats () {
    var allCookieStats = document.getElementsByClassName("cookie-stats");
    if (document.cookie.match(this.cookieName) !== null) { 
        var i;
        for (i = 0; i < allCookieStats.length; i++) {
          allCookieStats[i].checked = false;
        }
        // this.functionTrue() 
    }
    else {

      var i;
      for (i = 0; i < allCookieStats.length; i++) {
        allCookieStats[i].checked = true;
      }
      // this.functionFalse()
    }
  }
  functionCookieTrue() {
    document.cookie = this.cookieName + this.cookieTrue;       
  }

  functionCookieFalse() {
    document.cookie = this.cookieName + this.cookieFalse; 
  }

  functionCheckboxTrue() {
    var allCookieStats = document.getElementsByClassName("cookie-stats");
    var i;
    for (i = 0; i < allCookieStats.length; i++) {
      allCookieStats[i].checked = true;
    }
    //console.log('test: functionCheckboxTrue')
  }

  functionCheckboxFalse() {
    var allCookieStats = document.getElementsByClassName("cookie-stats");
    var i;
    for (i = 0; i < allCookieStats.length; i++) {
      allCookieStats[i].checked = false;
    }
    //console.log('test: functionCheckboxFalse')
  }

  denyCookieAll () {
    this.functionCheckboxFalse()
    this.functionCookieTrue()
  }

  acceptCookieAll () {

    this.functionCheckboxTrue() 
    this.functionCookieFalse()
  }

  acceptStatsCheckbox() {
    if ( document.getElementById('cookie-stats').checked !== true) { 
      this.functionCookieTrue()
      this.functionCheckboxFalse()

    } else {
      this.functionCookieFalse()
      this.functionCheckboxTrue() 
    }
  }
  
  saveSettings () {
   
  }  
}

window.customElements.define('flynt-block-cookieconsent', BlockCookieConsent, { extends: 'div' })
